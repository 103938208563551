<template>

  <div>

    <user-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :plan-options="planOptions"
      @refetch-data="refetchData"
    />

    <!-- Filters -->
    <users-list-filters
      :role-filter.sync="roleFilter"
      :plan-filter.sync="planFilter"
      :status-filter.sync="statusFilter"
      :plan-options="planOptions"
      :status-options="statusOptions"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="mb-1 ml-2 mr-2 mt-2">

        <!-- Table Top -->
        <b-row>
          <b-col v-if="$route.path.split('/')[5] === 'student'">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              class="btn-icon text-nowrap mr-25"
              @click="selectedFilter = { label: 'Requested renaming', value: 'requested_naming' }"
            >
              <span>Renamig requests</span>
              <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                {{ renamingRequests }}
              </span>
            </b-button>
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-primary"
                class="btn-icon text-nowrap mr-25 ml-2"
                @click="exportExcelDoc"
            >
              <b-spinner small v-if="isLoadingExcelDoc" class="mr-1"/>
              <span >Export list</span>
            </b-button>
          </b-col>

          <b-col
            cols="12"
            :md="$route.name === 'apps-users-list-administrator' ? '6' : '2'"
          >
              <v-select
                v-model="selectedFilter"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="$route.name === 'apps-users-list-administrator' ? filters.filter(el => el.value !== 'requested_naming') : filters"
                lebel="label"
                :clearable="false"
              />
          </b-col>
          <b-col
            v-show="$route.name !== 'apps-users-list-administrator'"
            cols="12"
            md="2"
          >
            <div class="d-flex align-items-center justify-content-start">
              <table id="tblForm" border="0" cellpadding="5" cellspacing="0" style="width: 100%;">
                  <!-- <tr class="text-nowrap d-block"><td >Birth Date</td></tr> -->
                  <tr><input id="dobInput" placeholder="DD/MM/YYYY" v-model="initialDate" type="text" class="date-format form-control"/></tr>
                  <tr class="p-0"><td><small class="error text-danger text-start" style="display: none; color: #ea5455 !important">Invalid Date. Only dd/MM/yyyy format allowed.</small></td></tr>
              </table>
            </div>
          </b-col>
          <!-- Search -->
          <b-col
            cols="12"
            :md="$route.path.split('/')[5] !== 'student' ? '6' : '2'"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block"
                :placeholder="$t('Search...')"
                debounce="500"
              />
              <b-button
                v-if="$route.path.split('/')[5] !== 'student'"
                variant="primary"
                class="ml-50"
                @click="isAddNewUserSidebarActive = true"
              >
                <span class="text-nowrap">Add User</span>
              </b-button>
            </div>
          </b-col>
          <!-- Informations alert -->
          <b-col
              cols="12"
              order-md="2"
              style="margin: 0;padding: 0;min-height: 36px;"
              v-if="$route.path.split('/')[5] === 'student'"
          >
            <b-alert
                variant="secondary"
                show
                v-if="selectedRows.length > 0"
                class="ml-1 mr-1"
            >
              <div class="alert-body text-center">
                <span>{{ selectAll ? dataMeta.of - unselectedRows.length : selectedRows.length }} entries are selected.</span>
                <b-Link
                    v-if="!selectAll"
                    @click="selectedRows = $store.state.userStoreModule.users.rows; selectAll = true"
                    style="font-weight: bolder"
                >
                  Select all {{ dataMeta.of }} entries
                </b-Link>
                <span  v-if="!selectAll"> or you can </span>
                <b-Link
                    style="font-weight: bolder"
                    @click="selectAll = false; selectedRows = []"
                >
                  Clear selection
                </b-Link>
              </div>
            </b-alert>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchUsers"
        responsive
        :fields=" $route.path.split('/')[5] === 'student' ? tableColumns : tableColumns1"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        :busy.sync="isBusy"
        :tbody-tr-class="tbodyRowClass"
        @row-clicked="onRowSelected"
      >
        <template #table-busy>
          <div
            v-if="isBusy"
            class="text-center"
          >
            <b-spinner
              variant="primary"
              label="Text Centered"
            />
            <strong class="ml-1">{{ $t('Loading...') }}</strong>
          </div>
        </template>
        <!-- Column: ID & select -->
        <template #cell(selected)="data">
          <b-form-checkbox
              :checked="selectedRows.some(el => el.id === data.item.id)"
              disabled
          >
          </b-form-checkbox>
          <small class="d-block mt-50 text-secondary">{{ data.item.id }}</small>
        </template>
        <!-- Column: User -->
        <template #cell(user)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="38"
                :src="data.item.avatar.split('/')[5] === 'default-avatar.jpg' ? null : data.item.avatar"
                :text="avatarText(data.item.name)"
                :variant="`light-${resolveUserRoleVariant(data.item.role)}`"
                :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
              />
            </template>
            <b-link
              v-b-tooltip.hover.top="data.item.name"
              :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap d-inline-block text-truncate"
              style="max-width: 300px;"
            >
              {{ data.item.name }}
            </b-link>
            <small class="text-muted">{{ data.item.email }}</small>
          </b-media>
        </template>
        <!-- Column: mail & phone -->
        <template #cell(email)="data">
          <div class="media-body">
            <div class="font-weight-bold d-block text-nowrap text-capitalize">
              {{ data.item.email }}
            </div>
          </div>
        </template>

        <!-- Column: country -->
        <template #cell(country)="data">
          <div class="media-body">
            <div class="font-weight-bold d-block text-nowrap text-capitalize">
              {{ data.item.country }}
            </div>
            <small class="text-muted">{{ data.item.city }}</small>
          </div>
        </template>
        <!-- Column: country -->
        <template #cell(dob)="data">
          <div class="media-body">
            <div class="font-weight-bold d-block text-nowrap text-capitalize">
              {{ data.item.dob }}
            </div>
          </div>
        </template>
        <template
          #cell(comments)="data"
        >
          <b-button
            :id="`popover-target-${data.item.id}`"
            variant="flat-primary"
            size="sm"
            class="btn-icon rounded-circle"
            @click="handleChatClick({model:'student', id: data.item.id})"
          >
            <feather-icon
              icon="ClipboardIcon"
              class="align-middle text-body"
              badge-classes="bg-danger"
              :badge="data.item.comments_number > 0 ? data.item.comments_number : ''"
              size="16"
            />
          </b-button>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="handleChatClick1(data.item.id)">
              <feather-icon icon="MessageSquareIcon" />
              <span class="align-middle ml-50">Contact</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="data.item.nameChangeRequest.length"
              v-b-modal="getModal1Id(data.item.id)"
            >
              <feather-icon icon="CheckCircleIcon" />
              <span class="align-middle ml-50">Validate name</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="$route.path.split('/')[5] === 'student'"
              v-b-modal="getModal3Id(data.item.id)"
              @click="getPlacementTest"
            >
              <feather-icon icon="SendIcon" />
              <span class="align-middle ml-50">Send placement test</span>
            </b-dropdown-item>
            <b-dropdown-item :to="{ name: 'apps-users-view', params: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Details</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="selectedFilter.value !== 'Deleted'" :to="{ name: 'apps-users-edit', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="selectedFilter.value !== 'Deleted'"
              v-b-modal="getModalId(data.item.id)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
            <!-- <b-dropdown-item
              v-if="$route.path.split('/')[5] === 'student'"
              @click="login(data.item.id)">
              <feather-icon icon="LogInIcon" />
              <span class="align-middle ml-50">Login</span>
            </b-dropdown-item> -->
            <b-modal
              :id="'modal3'+data.item.id"
              title="Send placement test"
              :ok-title="$t('Send')"
              cancel-title="Cancel"
              centered
              size="lg"
              no-close-on-backdrop
              @ok="sendPTest([data.item.id], selectedTest.id, due_date)"
            >
              <div
                v-if="!isLoadedTests"
                class="text-center d-flex align-items-center justify-content-center"
              >
                <b-spinner
                  variant="primary"
                  label="Text Centered"
                />
              </div>
              <div v-if="isLoadedTests">
                <b-form-group
                  :label="$t('Test')"
                  label-for="test"
                >
                  <v-select
                    v-model="selectedTest"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="tests"
                    :clearable="false"
                  />
                </b-form-group>
                <b-form-group
                  :label="$t('Due date')"
                  label-for="due-date"
                >
                  <flat-pickr
                    v-model="due_date"
                    class="form-control"
                    name="date"
                    :placeholder="$t('Due date')"
                    :config="{ enableTime: true, dateFormat: 'Y-m-d H:i:ss', locale: { firstDayOfWeek: 1 }}"
                  />
                </b-form-group>
              </div>
            </b-modal>
            <b-modal
              :id="'modal'+data.item.id"
              :title="$t('Delete')"
              ok-title="Yes"
              cancel-title="No"
              @ok="remove(data.item.id)"
            >
              <b-card-text>
                <h5>{{ $t('Are you sure you want to delete') }} <strong>{{ data.item.name }}</strong></h5>
              </b-card-text>
            </b-modal>
            <b-modal
              :id="'modal1'+data.item.id"
              :title="data.item.name"
              centered
              no-close-on-backdrop
              hide-footer
            >
              <b-form-group label="Old name">
                <b-form-input
                  v-model="data.item.name"
                  class="d-inline-block mr-1"
                  disabled
                />
              </b-form-group>
              <b-form-group label="New name">
                <b-form-input
                  v-model="data.item.nameChangeRequest"
                  class="d-inline-block mr-1"
                  disabled
                />
              </b-form-group>
              <br>
              <div class="d-flex justify-content-end">
                <b-button
                  variant="success"
                  class="mr-1"
                  @click="validateNewName(data.item.nameChangeRequest, data.item.id)"
                >
                  <span class="text-nowrap">Accept</span>
                </b-button>
                <b-button
                  variant="danger"
                  @click="rejectRename(data.item.id)"
                >
                  <span class="text-nowrap">Reject</span>
                </b-button>
              </div>
            </b-modal>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            sm="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('Show') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t('entries') }}</label>
          </b-col>

          <b-col
            cols="12"
            sm="3"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ $t('Showing') }} {{ dataMeta.from }} {{ $t('to') }} {{ dataMeta.to }} {{ ($t('of')) }} {{ dataMeta.of }} {{ $t('entries') }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-if="!isBusy"
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
    <div
      v-if="isChatActive"
      class="notesModule"
    >
      <div style="background: #fff" class="pb-1">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="light"
          size="sm"
          class="btn-icon shadow-sm ml-auto "
          @click="isChatActive = false"
          style="display: block; transform: translate(8px, -2px);"
        >
          <feather-icon icon="XIcon" />
        </b-button>
      </div>
      <chat-module
        :key="chatModelId.id"
        @decrease-notes="refetchData"
        @notes-increament="refetchData"
        :model-id="chatModelId.id"
        :model-type="chatModelId.model"
      />
    </div>
    <div
      v-if="isChatActive1"
      class="commentschats"
    >
      <feather-icon
        icon="XSquareIcon"
        size="22"
        class="align-middle text-body close-btn"
        @click="isChatActive1 = false"
      />
      <chat-module
        :conversation="conversation"
        :messages="messages"
      />
    </div>
  </div>
</template>

<script>
import {
  BCard, BCardText, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BDropdown, BDropdownItem, BPagination, BModal, BSpinner, VBTooltip, BFormGroup, BFormCheckbox, BAlert,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import formValidation from '@core/comp-functions/forms/form-validation'
// import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { useToast } from 'vue-toastification/composition'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import ChatModule from '@/views/admin/ChatModule.vue'
import { getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import UsersListFilters from './UsersListFilters.vue'
import useUsersList from './useUsersList'
import userStoreModule from '../userStoreModule'
import UserListAddNew from './UserListAddNew.vue'

export default {
  name: 'UsersList',
  components: {
    // ValidationProvider,
    // ValidationObserver,

    UsersListFilters,
    UserListAddNew,
    ChatModule,

    BCard,
    BCardText,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BDropdown,
    BDropdownItem,
    BPagination,
    BModal,
    BSpinner,
    BFormGroup,
    BFormCheckbox,
    BAlert,

    vSelect,
    flatPickr,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      userData: null,
      isChatActive1: false,
      conversation: {},
      messages: {},
    }
  },
  created() {
    const userData = getUserData()
    this.userData = userData
  },
  mounted() {
    /* eslint-disable camelcase, no-param-reassign, no-restricted-globals, radix, camelcase, no-shadow */
    let isShift = false
    const seperator = '/'
    const inputs = document.getElementById('tblForm').getElementsByTagName('input')
    function IsNumeric(input, keyCode) {
      if (keyCode === 16) isShift = true
      // Allow only Numeric Keys.
      if (((keyCode >= 48 && keyCode <= 57) || keyCode === 8 || keyCode <= 37 || keyCode <= 39 || (keyCode >= 96 && keyCode <= 105)) && isShift === false) {
          if ((input.value.length === 2 || input.value.length === 5) && keyCode !== 8) {
              input.value += seperator
              this.initialDate = input.value
          }
          return true
      }
      return false
    }
    function ShowHideError(textbox, display) {
        const row = textbox.parentNode.parentNode
        const errorMsg = row.getElementsByTagName('small')[0]
        if (errorMsg !== null) errorMsg.style.display = display
    }
    function ValidateDateFormat(input, keyCode) {
      let dateString = input.value
        if (dateString.length === 10) {
          const PureInput = dateString.replace('/', '').replace('/', '')
          input.value = `${PureInput.slice(0, 2)}/${PureInput.slice(2, 4)}/${PureInput.slice(4, 8)}`
          dateString = input.value
        }
        if (keyCode === 16) isShift = false
        const regex = /(((0|1)[0-9]|2[0-9]|3[0-1])\/(0[1-9]|1[0-2])\/((19|20)\d\d))$/
        // Check whether valid dd/MM/yyyy Date Format.
        if (regex.test(dateString) || dateString.length === 0) {
            ShowHideError(input, 'none')
            document.getElementById('dobInput').classList.remove('is-invalid')
            document.getElementById('dobInput').classList.add('is-valid')
        } else {
            ShowHideError(input, 'block')
            document.getElementById('dobInput').classList.remove('is-valid')
            document.getElementById('dobInput').classList.add('is-invalid')
        }
    }
    // Loop through all INPUT elements.
    for (let i = 0; i < inputs.length; i += 1) {
      // Check whether the INPUT element is TextBox.
      if (inputs[i].type === 'text') {
        // Check whether Date Format Validation is required.
        if (inputs[i].className.indexOf('date-format') !== 1) {
            // Set Max Length.
            inputs[i].setAttribute('maxlength', 10)
            // Only allow Numeric Keys.
            inputs[i].onkeydown = function (e) {
                return IsNumeric(this, e.keyCode)
            }
            // Validate Date as User types.
            inputs[i].onkeyup = function (e) {
              ValidateDateFormat(this, e.keyCode)
            }
        }
      }
    }
  },
  watch: {
    $route() {
      this.clearSelected()
    },
  },
  methods: {
    tbodyRowClass(item) {
      /* Style the row as needed */
      if (item && this.selectedRows.some(el => el.id === item.id)) return ['b-table-row-selected', 'cursor-pointer']
      return ['cursor-pointer']
    },
    clearSelected() {
      this.selectedRows = []
    },
    selectAllRows() {
      console.log('selectAllRows users')
      this.$store.state.userStoreModule.users.rows.forEach(el => {
        this.selectedRows.push(el)
      })
      // this.$refs.refUserListTable.selectAllRows()
    },
    handleChatClick1(id) {
      this.$http.post('/conversation', { participants: [id], user_id: this.userData.id })
      .then(res => {
        this.isChatActive1 = true
        this.conversation = {
          id: res.data.id,
          avatar: res.data.avatar,
          name: res.data.name,
        }
        this.messages = {
          data: res.data.messages,
        }
      })
    },
    login(id) {
      this.$http.post(`/login/as/student/${id}`).then(res => {
        localStorage.altToken = localStorage.accessToken
        localStorage.altUserData = localStorage.userData
        const tokens = res.data.accessToken.split('.')
        const decodedToken = JSON.parse(atob(tokens[1]))
        localStorage.accessToken = res.data.accessToken
        if (typeof decodedToken !== 'undefined') {
          localStorage.userData = JSON.stringify(decodedToken)
          if (decodedToken.language && decodedToken.language.length) {
            this.$i18n.locale = decodedToken.language
          }
          this.$ability.update(decodedToken.ability)
          this.$router.replace(getHomeRouteForLoggedInUser(decodedToken.role, 'fromLogin'))
          .then(() => {
            store.state.appConfig.isLoggedIn = true
            store.state.appConfig.adminLoggedAsStudent = true
            window.location.reload()
          })
        }
      })
    },
  },
  setup() {
    const getModalId = id => `modal${id}`
    const getModal1Id = id => `modal1${id}`
    const getModal2Id = id => `modal2${id}`
    const getModal3Id = id => `modal3${id}`
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const isAddNewUserSidebarActive = ref(false)
    const planOptions = [
      { label: 'Basic', value: 'basic' },
      { label: 'Company', value: 'company' },
      { label: 'Enterprise', value: 'enterprise' },
      { label: 'Team', value: 'team' },
    ]

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]
    // Use toast
    const toast = useToast()

    const onRowSelected = item => {
      const idToCheck = item.id
      if (selectedRows.value.some(el => el.id === idToCheck)) {
        // If already selected, remove it from selectedRows and add it to unselectedRows
        selectedRows.value = selectedRows.value.filter(el => el.id !== idToCheck)
        unselectedRows.value.push(item)
      } else if (unselectedRows.value.some(el => el.id === idToCheck)) {
        // If already unselected, remove it from unselectedRows and add it to selectedRows
        unselectedRows.value = unselectedRows.value.filter(el => el.id !== idToCheck)
        selectedRows.value.push(item)
      } else {
        // If not selected or unselected, add it to selectedRows
        selectedRows.value.push(item)
      }
    }

    const {
      isBusy,
      initialDate,
      fetchUsers,
      exportExcelDoc,
      isLoadingExcelDoc,
      selectedRows,
      unselectedRows,
      selectAll,
      tableColumns,
      tableColumns1,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      phone,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      renamingRequests,

      search_dob,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      selectedFilter,
      filters,
    } = useUsersList()

    const tests = ref([])
    const selectedTest = ref({})
    const due_date = ref(null)
    const isLoadedTests = ref(false)
    const getPlacementTest = () => {
      store.dispatch('registerStoreModule/getPlacementTest')
      .then(response => {
        tests.value = response.data.rows
        isLoadedTests.value = true
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while confirming registration',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    const sendPTest = (students, placement_test_id, due_date) => {
      store.dispatch('registerStoreModule/sendPTest', { students, placement_test_id, due_date })
      .then(response => {
          toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          refetchData()
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while confirming registration',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    const formatDate = function (dateTime) {
      console.log(this.$refs.refFormObserver)
      if (this.$refs.refFormObserver.flags.valid) {
        if (typeof dateTime !== 'undefined') {
          const timestamp = new Date(dateTime).getTime()
          const todate = new Date(timestamp).getDate()
          const tomonth = new Date(timestamp).getMonth() + 1
          const toyear = new Date(timestamp).getFullYear()

          const today = `0${todate.toString().slice(-2)}`
          const thisMonth = `0${tomonth.toString().slice(-2)}`
          const day = (today.length === 2 ? today : today.substr(1))
          const month = (thisMonth.length === 2 ? thisMonth : thisMonth.substr(1))

          const original_date = `${toyear}-${month}-${day}`
          search_dob.value = original_date
        }
      }
      return ''
    }
    const remove = id => {
      store.dispatch('app-user/deleteUser', id)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'User Has Been deleted',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          refetchData()
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while deleting user',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    const validateNewName = (new_name, user_id) => {
      store.dispatch('app-user/validateNewName', { new_name, user_id })
      .then(res => {
          toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          refetchData()
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while validating the new name',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    const rejectRename = user_id => {
      store.dispatch('app-user/rejectRename', user_id)
      .then(res => {
          toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          refetchData()
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while rejecting the new name',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    const isChatActive = ref(false)
    const chatModelId = ref(null)
    const handleChatClick = data => {
      isChatActive.value = true
      chatModelId.value = data
    }
    const {
      refFormObserver,
      getValidationState,
    } = formValidation(() => {})

    return {
      isBusy,
      sendPTest,
      getPlacementTest,
      tests,
      selectedTest,
      due_date,
      isLoadedTests,
      selectedRows,
      onRowSelected,
      unselectedRows,
      selectAll,

      refFormObserver,
      getValidationState,

      getModalId,
      getModal1Id,
      getModal2Id,
      getModal3Id,
      remove,
      validateNewName,
      rejectRename,

      initialDate,
      search_dob,
      formatDate,
      renamingRequests,

      isChatActive,
      chatModelId,
      handleChatClick,
      // Sidebar
      isAddNewUserSidebarActive,

      fetchUsers,
      exportExcelDoc,
      isLoadingExcelDoc,
      tableColumns,
      tableColumns1,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      phone,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      filters,
      selectedFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.alert {
  margin-bottom: 0;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.b-table-row-selected {
  background-color: #f4f4f4;
}
</style>
